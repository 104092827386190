import { getNumdoc, getTimeperpage } from '../../services/okrs.service'
import { Numdocschart } from '../../factories/staticGraphicFactory'
import moment from "moment";
export const OkrsMixin = {
    data() {
        return {
            loading: false,
            numdocs_: [],
            numdocschart_: null,
            avgtime_: [],
            avgchart_: null,
            timeperpage_: []
        };
    },
    created() {
        moment.locale('pt-br')
    },
    mounted() {
        this.numDocs()
        this.avgChart()
    },
    filters: {},
    watch: {},
    computed: {

    },

    methods: {
        async numDocs() {
            const { data } = await getNumdoc()
            this.numdocs_ = this.$duplicate(data.data)
            this.numdocs_.map(val => {
                let data = new Date(val._id.year, val._id.month, 1, 0, 0, 0)
                return val.dados = {
                    'label': `${val._id.month}/${val._id.year}`,
                    'total': val.count,
                    'data': data
                }

            })
            this.numdocs_.sort((a, b) => {
                return a.dados.data - b.dados.data
            })
            this.mountNumdocschart()

        },
        async avgChart() {
            const { data } = await getTimeperpage()
            this.avgchart_ = this.$duplicate(data.data)
            this.avgchart_.map(val => {
                if (val.average_time_per_page === null) {
                    val.average_time_per_page = 0
                } else {
                    val.average_time_per_page = Math.floor(val.average_time_per_page)
                }
                let data = new Date(val.month)
                val.data = data
                val.dados = {
                    label: `${data.getMonth() + 1}/${data.getFullYear()}`,
                    total: val.average_time_per_page,
                }
                return val
            })
            this.numdocs_.sort((a, b) => {
                return a.data - b.data
            })
            this.mountAvgchart()


        },

        mountNumdocschart() {
            let data = this.numdocs_
            this.numdocschart_ = new Numdocschart(data, 'Número de documentos')
                //console.log(this.numdocschart_)
        },
        mountAvgchart() {
            let data = this.avgchart_
            this.avgchart_ = new Numdocschart(data, 'Average Time per page')
                //console.log(this.avgchart_)
        },
    }
};